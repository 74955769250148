import plugin_vue3_1syf6Ev9qj from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.4.11_vue@3.4.15/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/web/.nuxt/components.plugin.mjs";
import unhead_DECdRGdCjh from "/opt/build/repo/node_modules/.pnpm/nuxt@3.4.3_@types+node@20.11.6_eslint@8.56.0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_QgVd6Xlf9a from "/opt/build/repo/node_modules/.pnpm/nuxt@3.4.3_@types+node@20.11.6_eslint@8.56.0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_aArd5qzsMu from "/opt/build/repo/node_modules/.pnpm/nuxt@3.4.3_@types+node@20.11.6_eslint@8.56.0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_8E5Q1LB4Uh from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+device@3.1.1/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import chunk_reload_client_GY2Z5M34vR from "/opt/build/repo/node_modules/.pnpm/nuxt@3.4.3_@types+node@20.11.6_eslint@8.56.0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import payload_client_nI4kpEjz38 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.4.3_@types+node@20.11.6_eslint@8.56.0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import sanity_image_url_0B93FesIEa from "/opt/build/repo/web/plugins/sanity-image-url.js";
import sanity_pDTw8KhLta from "/opt/build/repo/web/plugins/sanity.js";
export default [
  plugin_vue3_1syf6Ev9qj,
  components_plugin_KR1HBZs4kY,
  unhead_DECdRGdCjh,
  router_QgVd6Xlf9a,
  prefetch_client_aArd5qzsMu,
  plugin_8E5Q1LB4Uh,
  chunk_reload_client_GY2Z5M34vR,
  payload_client_nI4kpEjz38,
  sanity_image_url_0B93FesIEa,
  sanity_pDTw8KhLta
]