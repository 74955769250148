<template>
  <NuxtLink
    :to="linkTo"
    :target="linkTarget"
    :rel="linkRel"
    class="link"
    @click="handleLinkClick"
    :class="{ 'router-link-active': isInternalRouteActive }"
  >
    <slot></slot>
  </NuxtLink>
</template>

<script setup>
import { defineProps, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const props = defineProps({
  linkType: {
    type: String,
    default: () => 'externalLink',
  },
  href: {
    type: String,
    default: () => '',
  },
  blank: {
    type: Boolean,
    default: () => false,
  },
  route: {
    type: String,
    default: () => '',
  },
  slug: {
    type: String,
    default: () => '',
  }
})

const route = useRoute()
const router = useRouter()

const internalRoute = computed(() => ({
  name: route.name === props.route ? 'index' : props.route || 'index',
  params: props.slug ? { slug: props.slug } : {},
}))

const isInternalRouteActive = computed(() => {
  return (
    (props.slug && route.fullPath.includes(`/${props.slug}`)) ||
    route.fullPath.startsWith(`/${props.route}`)
  );
})

const linkTo = computed(() => (props.linkType === 'externalLink' ? props.href : internalRoute.value))
const linkTarget = computed(() => (props.blank && props.linkType === 'externalLink' ? '_blank' : ''))
const linkRel = computed(() => (props.blank && props.linkType === 'externalLink' ? 'noopener' : ''))

const handleLinkClick = () => {
  if (route.name === props.route) {
    router.push({ name: 'index' }) // Use router.push instead of route.push
  }
}
</script>


<style scoped>
.Navigation_Item .router-link-active {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-content: center;
}
.Navigation_Item .router-link-active::after {

  @media (min-width: token(width.md)) {
	content:url('~/assets/icons/Cross.svg');
	font-size: 100%;
	position: absolute;
	transform-origin: center;
	transform: translate(0, 95%);
  }
}
</style>
