import { default as _91slug_93WtN98UHbCDMeta } from "/opt/build/repo/web/pages/[slug].vue?macro=true";
import { default as abouteu7z9yhkxqMeta } from "/opt/build/repo/web/pages/about.vue?macro=true";
import { default as contactEzuccX9goCMeta } from "/opt/build/repo/web/pages/contact.vue?macro=true";
import { default as indexCEu1k4eofFMeta } from "/opt/build/repo/web/pages/index.vue?macro=true";
import { default as _91slug_9357j4X4NTnUMeta } from "/opt/build/repo/web/pages/projects/[slug].vue?macro=true";
import { default as indexjwvIWrHRtfMeta } from "/opt/build/repo/web/pages/projects/index.vue?macro=true";
import { default as servicesF4FBV5JOFoMeta } from "/opt/build/repo/web/pages/services.vue?macro=true";
import { default as sustainability2YbeZlJwrVMeta } from "/opt/build/repo/web/pages/sustainability.vue?macro=true";
export default [
  {
    name: _91slug_93WtN98UHbCDMeta?.name ?? "slug",
    path: _91slug_93WtN98UHbCDMeta?.path ?? "/:slug()",
    meta: _91slug_93WtN98UHbCDMeta || {},
    alias: _91slug_93WtN98UHbCDMeta?.alias || [],
    redirect: _91slug_93WtN98UHbCDMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/web/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: abouteu7z9yhkxqMeta?.name ?? "about",
    path: abouteu7z9yhkxqMeta?.path ?? "/about",
    meta: abouteu7z9yhkxqMeta || {},
    alias: abouteu7z9yhkxqMeta?.alias || [],
    redirect: abouteu7z9yhkxqMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/web/pages/about.vue").then(m => m.default || m)
  },
  {
    name: contactEzuccX9goCMeta?.name ?? "contact",
    path: contactEzuccX9goCMeta?.path ?? "/contact",
    meta: contactEzuccX9goCMeta || {},
    alias: contactEzuccX9goCMeta?.alias || [],
    redirect: contactEzuccX9goCMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/web/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: indexCEu1k4eofFMeta?.name ?? "index",
    path: indexCEu1k4eofFMeta?.path ?? "/",
    meta: indexCEu1k4eofFMeta || {},
    alias: indexCEu1k4eofFMeta?.alias || [],
    redirect: indexCEu1k4eofFMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_9357j4X4NTnUMeta?.name ?? "projects-slug",
    path: _91slug_9357j4X4NTnUMeta?.path ?? "/projects/:slug()",
    meta: _91slug_9357j4X4NTnUMeta || {},
    alias: _91slug_9357j4X4NTnUMeta?.alias || [],
    redirect: _91slug_9357j4X4NTnUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/web/pages/projects/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexjwvIWrHRtfMeta?.name ?? "projects",
    path: indexjwvIWrHRtfMeta?.path ?? "/projects",
    meta: indexjwvIWrHRtfMeta || {},
    alias: indexjwvIWrHRtfMeta?.alias || [],
    redirect: indexjwvIWrHRtfMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/web/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: servicesF4FBV5JOFoMeta?.name ?? "services",
    path: servicesF4FBV5JOFoMeta?.path ?? "/services",
    meta: servicesF4FBV5JOFoMeta || {},
    alias: servicesF4FBV5JOFoMeta?.alias || [],
    redirect: servicesF4FBV5JOFoMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/web/pages/services.vue").then(m => m.default || m)
  },
  {
    name: sustainability2YbeZlJwrVMeta?.name ?? "sustainability",
    path: sustainability2YbeZlJwrVMeta?.path ?? "/sustainability",
    meta: sustainability2YbeZlJwrVMeta || {},
    alias: sustainability2YbeZlJwrVMeta?.alias || [],
    redirect: sustainability2YbeZlJwrVMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/web/pages/sustainability.vue").then(m => m.default || m)
  }
]