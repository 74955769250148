<template>
  <footer class="TheFooter">
    <p class="text-base">
      {{ mainStore.siteOptions?.copyrightText }}, {{ currentYear }}
    </p>
    <TheFooterNavigation />
  </footer>
</template>

<script setup>
const props = defineProps({
})
// content
const mainStore = useMainStore()
const currentYear = new Date().getFullYear()


</script>

<style scoped>
/* optional display with class .visible*/
/* .TheFooter {
  opacity: 0;
  display: none;
  pointer-events: none;
} */

/* this would be class .visible*/
/* .TheFooter.visible { */
.TheFooter {
  opacity: 1;
  pointer-events: all;
  position: relative;
  display: grid;
  gap: 1rem;
  width: 100%;
  padding: var(--base-padding);
  margin: auto 0 0 0;
  @media (min-width: token(width.sm)) {
    display: flex;
    gap: 4rem;

  }
}
</style>
