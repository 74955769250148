<template>
  <header
    class="TheHeader"
    v-on-click-outside="mainStore.closeMenu"
    :class="{ toggle: down, menuopen: mainStore.menuIsOpen }"
  >
    <div
      v-if="isMobileOrTablet "
      class="Mobile_Menu_Background"
      :class="{ toggle: down, menuopen: mainStore.menuIsOpen }"
    ></div>
    <div class="TheHeader_Bar">
      <div class="Logo_Container" :class="{ toggle: down }">
        <NuxtLink to="/">
          <img
            id="Logo"
            src="/SouvenirCollective_Logo-1Line_RGB.png"
            alt="Souvenir Collective Logo"
          />
        </NuxtLink>
      </div>
      <TheHeaderNavigation
        class="TheHeader_Main_Menu"
        v-if="!isMobileOrTablet"
        :class="{ toggle: down }"
      />
      <button
        @click="toggleMenu"
        class="TheHeader_Mobile_Button textlink text-base"
      >
        <span
          v-show="!mainStore.menuIsOpen"
          class="TheHeader_Mobile_Menutoggle"
        >
          More
        </span>
        <span v-show="mainStore.menuIsOpen" class="TheHeader_Mobile_Menutoggle">
          Close
        </span>
      <transition name="fade">

        <span class="TheHeader_Mobile_Path" v-if="isMobileOrTablet && currentRoute">{{
          currentRoute
        }}</span>
      </transition>
      </button>
      <transition name="fade">
        <div
          class="TheHeader_Mobile_Menubar"
          v-show="mainStore.menuIsOpen"
        ></div>
      </transition>
      <transition name="fade">
        <TheHeaderNavigation
          class="TheHeader_Mobile_Menu"
          v-show="mainStore.menuIsOpen"
          @click="toggleMenu"
        />
      </transition>
    </div>
  </header>
</template>

<script setup>
import { vOnClickOutside } from '@vueuse/components'
import { useWindowScroll } from '@vueuse/core'
import { useScroll } from '@vueuse/core'
import { onMounted } from 'vue'

const { isMobileOrTablet } = useDevice()

// get content
const route = useRoute()
const mainStore = useMainStore()

// Function to extract the first level route
const getFirstLevelRoute = (fullPath) => {
  const segments = fullPath.split('/')
  const pathName = segments[1].charAt(0).toUpperCase() + segments[1].slice(1)
  return pathName || '' // Index 1 corresponds to the first level route
}

const currentRoute = ref(getFirstLevelRoute(route.path))

//scrolling
const { y } = useWindowScroll()
const previousY = ref(0)

const down = computed(() => {
  return y.value >= previousY.value + 50
    ? true
    : y.value <= previousY.value - 25
    ? false
    : down.value
})

// Log changes to 'down' when it updates
watch(
  () => down.value,
  (newVal) => {}
)

watch(
  () => y.value,
  (newVal, oldVal) => {
    if (newVal >= previousY.value + 50 || newVal <= previousY.value - 25) {
      previousY.value = oldVal
    }
  }
)

// Watch for changes in the route
watchEffect(() => {
  currentRoute.value = getFirstLevelRoute(route.path)
})

/*
mobile menu
*/
const toggleMenu = () => {
  mainStore.toggleMenu()
}

onMounted(() => {
  // Save the initial scroll position
  const initialScrollY = window.scrollY

  // Watch for changes in the menuIsOpen value
  watch(
    () => mainStore.menuIsOpen.value,
    (isOpen) => {
      // Prevent scrolling when the menu is open
      if (isOpen) {
        // Add a class to the body to disable scrolling
        document.body.classList.add('no-scroll')
      } else {
        // Remove the class and restore the scroll position
        document.body.classList.remove('no-scroll')
        window.scrollTo(0, initialScrollY)
      }
    }
  )
})
</script>

<style scoped>
.TheHeader {
  position: sticky;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: var(--base-padding) var(--base-padding) var(--mid-margin);
  transition: transform 0.4s ease, background-color 2s ease,
    backdrop-filter 4s ease;
  top: 0;
  z-index: 10;
  @media (min-width: token(width.md)) {
    position: sticky;
    background-color: transparent;
    backdrop-filter: blur(0);
  }
}

.Mobile_Menu_Background {
  height: 150%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  transition: background-color 2s ease, backdrop-filter 1.25s ease;
  /* background-color: white; */
  background-color: rgba(255, 255, 255, 0.25);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.05) 29%,
    rgba(255, 255, 255, 0) 100%
  );
  backdrop-filter: blur(10px);
  mask: linear-gradient(black 50%, transparent 100%);
  @media (min-width: token(width.md)) {
    display: none !important;
  }
}

.Mobile_Menu_Background.toggle {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  transition: background-color 2s ease, backdrop-filter 0.5s ease;
  /* background-color: white; */
  background-color: rgba(255, 255, 255, 0);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 29%,
    rgba(255, 255, 255, 0) 100%
  );
  backdrop-filter: blur(0px);
  mask: linear-gradient(black 60%, transparent 100%);
}

.TheHeader_Bar {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.TheHeader_Bar .Logo_Container {
  width: 100%;
  height: min-content;
  z-index: 99999;
  @media (min-width: token(width.md)) {
    width: clamp(250px, 21.5%, 375px);
    transition: transform 0.4s ease;
  }
}

.TheHeader_Bar .Logo_Container #Logo {
  object-fit: contain;
  width: 101%;
  margin-left: -0.5%;
}

.TheHeader_Main_Menu {
  display: none;
  @media (min-width: token(width.md)) {
    display: block;
    transition: transform 0.4s ease;
  }
}

.TheHeader_Mobile_Path {
  margin: 0 0 0 var(--base-margin);
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: center;
}

.TheHeader_Mobile_Path::after {
  content: url('~/assets/icons/Cross.svg');
  font-size: 100%;
  position: absolute;
  transform-origin: center;
  transform: translate(0, 95%);
}

.TheHeader_Mobile_Button {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 99999;
  @media (min-width: token(width.md)) {
    display: none;
  }
}

.TheHeader_Mobile_Button span {
  text-align: left;
}

.TheHeader_Mobile_Menutoggle {
  min-width: 60px !important;
  width: 60px;
}

.TheHeader_Mobile_Menu {
  position: absolute;
  height: max-content;
  width: 100%;
  height: calc(100vh - (var(--base-margin) * 2));
  transform-origin: center;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: flex-start;
  /* top: calc(50vh - var(--big-margin)); */
  @media (min-width: token(width.lg)) {
    display: none;
  }
}

.TheHeader_Mobile_Menubar {
  position: fixed;
  left: 0;
  top: 0;
  background-color: white;
  height: 100vh;
  width: 100vw;
  z-index: -1;
}

/* toggle state*/

.TheHeader.toggle {
  transform: translate(0, -150%);
  background-color: rgba(255, 255, 255, 0);
  backdrop-filter: blur(2px);
  @media (min-width: token(width.md)) {
    background-color: rgba(255, 255, 255, 0);
    backdrop-filter: blur(0);
  }
}
.TheHeader.toggle.menuopen {
  transform: translate(0, 0%);
  background-color: rgba(255, 255, 255, 0);
  backdrop-filter: blur(2px);
  @media (min-width: token(width.md)) {
    background-color: rgba(255, 255, 255, 0);
    backdrop-filter: blur(0);
  }
}

.TheHeader_Bar .Logo_Container.toggle {
  transform: translate(0, 0);
  @media (min-width: token(width.md)) {
    transform: translate(0, -150%);
  }
}

.TheHeader_Bar .TheHeader_Main_Menu.toggle {
  @media (min-width: token(width.md)) {
    transform: translate(0, -150%);
  }
}
</style>
