import { contentBlockQuery, seoQuery, linkQuery } from '@/queries/helperQueries'

export const siteQuery = groq`{
	"siteOptions": *[_id == "siteOptions"] [0] {
		...,
		footerLinks[] {
			${linkQuery}
		},
		cookieText[]{
			${contentBlockQuery}
		},
		${seoQuery}
	},
	"siteNav": *[_id == "siteNav"] [0] {
		navMain[]{
			${linkQuery}
		},
		navFooter[]{
			${linkQuery}
		},
	},
	"slugs": {
		"pages": *[_type == "page"].slug.current,
		"projects": *[_type == "project"].slug.current,
	}
}`

export const homeQuery = groq`*[(
	_type == "pageHome")] | order(_updatedAt desc) [0]{
		...,
		projects[]->{_id, client[]->{...}, title, subtitle, slug, titleImage{..., asset->}, "video": titleVideo{...,muxVideo{..., asset->}}},
		${seoQuery}
}`

export const aboutQuery = groq`*[(
	_type == "pageAbout")][0]{
		...,
		about[]{
		  "headline": aboutHeadline,
		  "description": serviceDescription,
		  _key,
		  ${contentBlockQuery}
		},
		${seoQuery}
}`

export const servicesQuery = groq`*[(
	_type == "pageServices")]| order(_updatedAt desc) [0]{
	...,
	serviceList[] {
		...,
		${contentBlockQuery}
	},
	serviceDescription[] {
		...,
		${contentBlockQuery}
	},
	${seoQuery}
}`

export const sustainabilityQuery = groq`*[(
	_type == "pageSustainability")][0]{
		...,
		${seoQuery}
}`

export const pageQuery = groq`
*[_type == 'page' && slug.current == $slug] | order(_updatedAt desc) [0]{
	...,
	content[] {
		${contentBlockQuery}
	},
	${seoQuery}
}
`
// V1
// export const projectsQuery = groq`
// *[(_type == "pageProjects")] | order(_updatedAt desc) [0]{
// 	...,
// 	"projects": *[_type == "project"] | order(subtitle desc, _updatedAt desc) {
// 		title, slug, subtitle, titleImage{..., asset->}, "video": titleVideo{...,muxVideo{..., asset->}}
// 	},
// 	${seoQuery}
// }
// `

// V2
// export const projectsQuery = groq`
// *[(_type == "pageProjects")] | order(_updatedAt desc) [0]{
// 	...,
// 	"projects": projects[]->{
// 		title, slug, subtitle, titleImage{..., asset->}, "video": titleVideo{...,muxVideo{..., asset->}}
// 	},
// 	${seoQuery}
// }
// `

// V3
export const projectsQuery = groq`
*[_type=='pageProjects'][0]{
	...,
	title,
	  'clients': projects[]->{
	  _id, 
	  title,
	  'projects': *[_type=='project' && references(^._id)] | order(subtitle desc) { title, "year": subtitle, slug, titleImage{..., asset->}, "video": titleVideo{...,muxVideo{..., asset->}} }
	}
	  
  }  
`

// export const singleProjectQuery = groq`
// *[_type == 'project' && slug.current == $slug] | order(_updatedAt desc) [0]{
// 	...,
// 	content[] {
// 		${contentBlockQuery}
// 	},
// 	titleImage{..., asset->},
// 	${seoQuery}
// }
// `

export const singleProjectQuery = groq`
*[_type == 'project' && slug.current == $slug] [0]{
	...,
	title,
	client[]->{...},
	"gallery": projects[]{..., asset->, muxVideo{..., asset->}},
	content[] {
		${contentBlockQuery}
	},
	${seoQuery}
}
`
